import React from 'react';
import About from 'containers/About';
import Contact from 'containers/Contact';
import FAQ from 'containers/FAQ';
import TermService from 'containers/TermService';
import Privacy from 'containers/Privacy';
import { clientRoutesEnum } from 'enums/routes';
import StaticLayout from 'layouts/StaticLayout';
import { Switch } from 'react-router-dom';
import Litepaper from 'containers/Litepaper';
import DonePage from 'containers/Done';

function StaticRoutes() {
  return (
    <Switch>
      <StaticLayout
        exact
        path={clientRoutesEnum.CONTACT}
        RenderComponent={Contact}
      />
      <StaticLayout
        exact
        path={clientRoutesEnum.DONE}
        RenderComponent={DonePage}
      />
      <StaticLayout
        exact
        path={clientRoutesEnum.ABOUT}
        RenderComponent={About}
      />
      <StaticLayout
        exact={false}
        path={clientRoutesEnum.FAQ}
        RenderComponent={FAQ}
      />
      <StaticLayout
        exact={true}
        path={clientRoutesEnum.TERMS_SERVICES}
        RenderComponent={TermService}
      />
      <StaticLayout
        exact={true}
        path={clientRoutesEnum.PRIVACY}
        RenderComponent={Privacy}
      />
      <StaticLayout
        exact={true}
        path={clientRoutesEnum.LITEPAPER}
        RenderComponent={Litepaper}
      />
    </Switch>
  );
}

export default StaticRoutes;
