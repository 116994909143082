import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useStyle } from './style';

export interface ICTAComponentProps {
  headTitle: string;
  subHeadTitle: string;
  firstTitleButton: string;
  secondTitleButton: string;
  iconButton?: ReactElement;
  iconPosition?: 'start' | 'end';
  handleClickFirstBtn?: () => void;
  handleClickSecondBtn?: () => void;
}

const CTAComponent: React.FC<ICTAComponentProps> = (
  props: ICTAComponentProps,
) => {
  const {
    headTitle,
    subHeadTitle,
    // firstTitleButton,
    // secondTitleButton,
    // handleClickFirstBtn,
    // handleClickSecondBtn,
    // iconButton,
    // iconPosition,
  } = props;
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <div className={classes.direct}>
        <Typography className={classes.head}>
          {headTitle} <img src="/images/handshake.png" alt="handshake" />
        </Typography>
        <Typography className={classes.subHead}>{subHeadTitle}</Typography>
        {/* <button className={classes.homeButton} onClick={handleClickFirstBtn}>
            {firstTitleButton}
          </button> */}
      </div>
    </div>
  );
};

export default CTAComponent;
