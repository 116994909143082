/* eslint-disable */
import { makeStyles, useTheme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useTitle from 'hooks/useTitle';

function Litepaper() {
  const classes = useStyles();
  const history = useHistory<{ signal: string }>();
  const theme = useTheme();

  const onRedirectHome = () => {
    history.push('/');
  };

  const onRedirectLitepaper = () => {
    history.push('/how-it-works');
  };

  useTitle('How it works | 1001 Squares of NFT');

  return (
    <div className={classes.wrapper}>
      <div className={classes.crumpet}>
        <span onClick={onRedirectHome}>Home · </span>
        <span
          onClick={onRedirectLitepaper}
          className={classes.crumpetTermserive}
        >
          How it works
        </span>
      </div>
      <div className={classes.container}>
        <Typography className={classes.title}>How it works</Typography>

        <div>
          <div>
            <h3 className={classes.subTitle}>
              1001 Squares of NFT lets you link NFTs to a wider audience with no
              commissions.
            </h3>
            <ul className={classes.text}>
              <li>
                <span className={classes.heading}>Step 1:</span> Add your unique{' '}
                <strong className={classes.hightlightText}>
                  NFT marketplace link
                </strong>
                .
              </li>
              <li>
                <span className={classes.heading}>Step 2:</span> Rank in up to{' '}
                <strong className={classes.hightlightText}>42 galleries</strong>{' '}
                from{' '}
                <strong className={classes.hightlightText}>$0.00 a day.</strong>
              </li>
              <li>
                <span className={classes.heading}>Step 3:</span> Pay in SOL,
                ETH, BSC, AVAX, POL, FTM, or DONE to rank higher.
              </li>
              <li>
                <span className={classes.heading}>Step 4:</span> Get back{' '}
                <strong className={classes.hightlightText}>99%</strong> in DONE
                when you pay in
                <strong className={classes.hightlightText}> SOL or DONE</strong>
                . *First month of launch offer, later decreases to 25%.
              </li>
              <li>
                <span className={classes.heading}>Step 5:</span> (Optional) Edit
                your NFT’s position in your profile at any time.
              </li>
            </ul>
            <img
              src="/images/1001_squares_of_nft_introduction.jpg"
              alt="1001_squares_of_nft_introduction"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
            />
          </div>
          <div>
            <h3 className={classes.subTitle}>About Us</h3>
            <p className={classes.text}>
              Inspired by the Million Dollar Homepage, our mission is to deliver
              the{' '}
              <strong className={classes.hightlightText}>#1 NFT space</strong>{' '}
              for the NFT community to link NFTs. We’ve partnered with Hashlock
              and Sourcehat to secure our token $DONE and the platform's payment
              systems (
              <a
                href="https://hashlock.com/audits/1001-squares-of-nft"
                target="_blank"
                rel="noreferrer"
              >
                Rust audit
              </a>
              ,{' '}
              <a
                href="https://sourcehat.com/audits/1001SquaresofNFT/"
                target="_blank"
                rel="noreferrer"
              >
                Solidity audit
              </a>
              ). 1001 Squares of NFT was created by Martim Molina,{' '}
              <a
                href="https://x.com/martim_sol"
                target="_blank"
                rel="noreferrer"
              >
                @martim_sol
              </a>{' '}
              on X.
            </p>
            <p className={classes.text}>
              <strong className={classes.hightlightText}>Join us</strong> in
              building the front page of NFT
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Litepaper;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    wrapper: {
      cursor: "url('/cursor/how_it_works_finger_cursor.svg'), auto",
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '200px 293px 153px 293px',
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
      [theme.breakpoints.down('md')]: {
        padding: '136px 24px 140px 24px',
      },
    },
    crumpet: {
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.secondaryLight,
      cursor: 'pointer',
      fontWeight: 500,
      position: 'relative',
      marginLeft: 103,
      [theme.breakpoints.down('md')]: {
        top: '136px',
        marginLeft: 24,
        marginBottom: 20,
      },
      top: '180px',
    },
    crumpetTermserive: {
      fontWeight: 700,
    },
    title: {
      fontSize: 42,
      fontWeight: 700,
      lineHeight: '58.8px',
      marginBottom: 12,
    },
    subTitle: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '27.6px',
      marginBottom: 8,
    },
    text: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '37.6px',
      marginBottom: 8,
      listStyleType: 'none',
      paddingLeft: 0,
    },
    heading: {
      fontWeight: 500,
    },
    hightlightText: {
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.secondaryLight,
    },
  };
});
