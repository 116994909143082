import { Grid, makeStyles } from '@material-ui/core';
import CompatibleWebsite from 'components/CompatibleWebsite';
import CopyText from 'components/CopyText';
import useTitle from 'hooks/useTitle';

const DONE_LOGO_LIST = [
  {
    name: 'raydium',
    logo: '/images/done/raydium_logo.svg',
    link: 'https://raydium.io/swap/',
  },
  // {
  //   name: 'coinmarketcap',
  //   logo: '/images/done/coinmarketcap_logo.svg',
  // },
  // {
  //   name: 'coingecko',
  //   logo: '/images/done/coingecko_logo.svg',
  // },
  {
    name: 'dexscreener',
    logo: '/images/done/dexscreener_logo.png',
    link: 'https://dexscreener.com/',
  },
];

const DonePage = () => {
  const classes = useStyles();
  useTitle('Done | 1001 Squares of NFT');
  return (
    <div className={classes.wrapper}>
      <div className={classes.crumpet}>
        <span>Home · </span>
        <span className={classes.crumpetTermserive}>$Done</span>
      </div>
      <div className={classes.container}>
        <h3 className={classes.title}>$DONE</h3>
        {/* <p className={classes.subTitle}>
          <CopyText copyText="3AzEpHb4SPrsZ7BZHezAXBexAVRwufMW5cabREGCakeo">
            3AzEpHb4SPrsZ7BZHezAXBexAVRwufMW5cabREGCakeo
          </CopyText>
        </p> */}
        <p className={classes.text}>
          $DONE on Solana is the native token of 1001 Squares of NFT. Get back{' '}
          {''}
          <strong className={classes.hightlightText}>99%</strong> in DONE when
          you add NFTs and pay in{' '}
          <strong className={classes.hightlightText}>SOL or DONE</strong>. First
          month of launch offer, later decreases to 25%.
          <br />
          <br />
          DONE price goes up each time SOL is converted to DONE rewards.
          <br />
          <br />
          No unlocks. Supply: 1,001
        </p>
        <Grid container spacing={2} className={classes.compatibleWebsite}>
          {DONE_LOGO_LIST.map((v) => (
            <CompatibleWebsite link={v.link} logoUrl={v.logo} key={v.name} />
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default DonePage;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    wrapper: {
      cursor: "url('/cursor/cursor_frame_00.gif') 32 32, auto",
      animation: '$cursor 1s linear infinite',
    },
    '@keyframes cursor': {
      '0%': {
        cursor: "url('/cursor/cursor_frame_00.gif') 32 32, auto",
      },
      '4%': {
        cursor: "url('/cursor/cursor_frame_01.gif') 32 32, auto",
      },
      '8%': {
        cursor: "url('/cursor/cursor_frame_02.gif') 32 32, auto",
      },
      '12%': {
        cursor: "url('/cursor/cursor_frame_03.gif') 32 32, auto",
      },
      '16%': {
        cursor: "url('/cursor/cursor_frame_04.gif') 32 32, auto",
      },
      '20%': {
        cursor: "url('/cursor/cursor_frame_05.gif') 32 32, auto",
      },
      '24%': {
        cursor: "url('/cursor/cursor_frame_06.gif') 32 32, auto",
      },
      '28%': {
        cursor: "url('/cursor/cursor_frame_07.gif') 32 32, auto",
      },
      '32%': {
        cursor: "url('/cursor/cursor_frame_08.gif') 32 32, auto",
      },
      '36%': {
        cursor: "url('/cursor/cursor_frame_09.gif') 32 32, auto",
      },
      '40%': {
        cursor: "url('/cursor/cursor_frame_10.gif') 32 32, auto",
      },
      '44%': {
        cursor: "url('/cursor/cursor_frame_11.gif') 32 32, auto",
      },
      '48%': {
        cursor: "url('/cursor/cursor_frame_12.gif') 32 32, auto",
      },
      '52%': {
        cursor: "url('/cursor/cursor_frame_13.gif') 32 32, auto",
      },
      '56%': {
        cursor: "url('/cursor/cursor_frame_14.gif') 32 32, auto",
      },
      '60%': {
        cursor: "url('/cursor/cursor_frame_15.gif') 32 32, auto",
      },
      '64%': {
        cursor: "url('/cursor/cursor_frame_16.gif') 32 32, auto",
      },
      '68%': {
        cursor: "url('/cursor/cursor_frame_17.gif') 32 32, auto",
      },
      '72%': {
        cursor: "url('/cursor/cursor_frame_18.gif') 32 32, auto",
      },
      '76%': {
        cursor: "url('/cursor/cursor_frame_19.gif') 32 32, auto",
      },
      '80%': {
        cursor: "url('/cursor/cursor_frame_20.gif') 32 32, auto",
      },
      '84%': {
        cursor: "url('/cursor/cursor_frame_21.gif') 32 32, auto",
      },
      '88%': {
        cursor: "url('/cursor/cursor_frame_22.gif') 32 32, auto",
      },
      '92%': {
        cursor: "url('/cursor/cursor_frame_23.gif') 32 32, auto",
      },
      '96%': {
        cursor: "url('/cursor/cursor_frame_24.gif') 32 32, auto",
      },
      '100%': {
        cursor: "url('/cursor/cursor_frame_00.gif') 32 32, auto",
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '200px 293px 153px 293px',
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
      [theme.breakpoints.down('md')]: {
        padding: '136px 24px 140px 24px',
      },
    },
    crumpet: {
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.secondaryLight,
      cursor: 'pointer',
      fontWeight: 500,
      position: 'relative',
      marginLeft: 103,
      [theme.breakpoints.down('md')]: {
        top: '136px',
        marginLeft: 24,
        marginBottom: 20,
      },
      top: '180px',
    },
    crumpetTermserive: {
      fontWeight: 700,
    },

    title: {
      fontSize: 42,
      fontWeight: 700,
      lineHeight: '58.8px',
      marginBottom: 12,
      marginTop: 20,
    },
    subTitle: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '30px',
      marginBottom: 8,
      wordBreak: 'break-all',
      overflow: 'hidden',
      height: 32,
    },
    text: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '27.6px',
      marginBottom: 8,
    },
    hightlightText: {
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.secondaryLight,
    },

    compatibleWebsite: {
      marginTop: 28,
      marginBottom: 76,
      [theme.breakpoints.down('md')]: {
        padding: '0px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  };
});
