/* eslint-disable */
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PlaceholderLoader from 'components/common/PlacehoderLoader';
import { Orientations } from 'enums/orientations';
import { DISPLAY_TYPE } from 'containers/Admin/TwelveBlocksExtra';

interface IData {
  orientation: Orientations;
  idBlock: number;
  includeDz?: boolean;
  blocksByTimePath?: string;
  processing?: boolean;
}

function TwelveBlocksPanelAdmin({
  orientation,
  includeDz,
  blocksByTimePath,
  idBlock,
  processing,
}: IData) {
  const classes = useStyle();

  const imgResult = useMemo(() => {
    const imageName =
      idBlock === DISPLAY_TYPE.INSTAGRAM_POST ? 'instagram' : idBlock;
    const imgUrl = `https://minted-gem-be-s3.s3.eu-west-1.amazonaws.com/${blocksByTimePath}/${imageName}.png`;

    if (orientation === Orientations.ORIGINAL) {
      if (idBlock === DISPLAY_TYPE.ORIGINAL)
        return (
          <>
            <div>
              <img src={imgUrl} alt="" />
            </div>
            {includeDz && <div className={classes.dzBlock}></div>}
          </>
        );
      return (
        <>
          <div style={{ marginLeft: 4 }}>
            <img src={imgUrl} width={90} height={90} alt="" />
          </div>
          {includeDz && <div className={classes.dzBlock}></div>}
        </>
      );
    }

    if (
      idBlock === DISPLAY_TYPE.TWITTER_POST ||
      idBlock === DISPLAY_TYPE.INSTAGRAM_POST
    )
      return (
        <div>
          <img src={imgUrl} alt="" />
        </div>
      );

    return (
      <div>
        <img
          src={imgUrl}
          width={377}
          height={377}
          style={{ objectFit: 'cover' }}
        />
      </div>
    );
  }, [blocksByTimePath, idBlock, orientation, includeDz]);

  return (
    <div>
      <div style={{ marginLeft: 4 }}>
        Block: {idBlock > 12 ? 'All blocks' : idBlock}
      </div>
      {processing ? (
        <PlaceholderLoader className={classes.placeholderLoader} />
      ) : (
        imgResult
      )}
    </div>
  );
}

export default TwelveBlocksPanelAdmin;

const useStyle = makeStyles(() => ({
  twelveBlocks: {
    marginLeft: 100,
    marginTop: 10,
    maxWidth: 800,
  },
  dzBlock: {
    width: 1048,
    height: 90,
    backgroundColor: '#DDE542',
    marginLeft: 4,
  },
  placeholderLoader: {
    height: 246,
    padding: 0,
    margin: 0,
    '& span': {
      width: 40,
    },
  },
}));
