import React from 'react';
import { SortEnum } from 'enums/sortEnum';
import DiscoverTop from './DiscoverTop';
import ScrollCategories from './ScrollCategories';
import TableCategories from './TableCategories';
import { DataCategories } from 'types/categories';
import { CategoriesLabelEnum, ParamsSortBy } from 'enums/categories';
import CTAComponent, {
  ICTAComponentProps,
} from 'components/common/CTAComponent';
import { ReactComponent as ExploreIcon } from 'assets/rocket-solid.svg';
import { useHistory } from 'react-router-dom';
import GifDone from 'assets/done_coin_rotating.gif';
import { makeStyles } from '@material-ui/core';

export interface PageHomeProps {
  allCategories: DataCategories[];
  categories: DataCategories[];
  pageSize: number;
  total: number;
  pageNumber: number;
  onChangePage: (isLoadMore?: boolean) => void;
  loading?: boolean;
  handleChangeDuration: (value: ParamsSortBy) => void;
  sortType: SortEnum;
  handleSort: (value: SortEnum, column?: CategoriesLabelEnum) => void;
  sortField: string;
}

export default function PageHome(props: PageHomeProps) {
  const {
    handleSort,
    allCategories,
    categories,
    pageSize,
    total,
    pageNumber,
    onChangePage,
    loading,
    handleChangeDuration,
    sortType,
    sortField,
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const ctaField: ICTAComponentProps = {
    headTitle: 'Add NFTs. Get 99% $DONE',
    subHeadTitle:
      'Add NFTs with $DONE or Solana and for a limited time get 99% of your ad spend instantly back in $DONE.',
    firstTitleButton: 'ABOUT',
    secondTitleButton: 'EXPLORE',
    iconButton: <ExploreIcon color="#FFFFFF" width={24} height={24} />,
    iconPosition: 'end',
    handleClickFirstBtn: () => history.push('/about'),
    handleClickSecondBtn: () => history.push('/nft'),
  };

  return (
    <div>
      <DiscoverTop categories={allCategories} />
      <ScrollCategories categories={allCategories} />
      <TableCategories
        loading={loading && loading}
        data={categories}
        count={total}
        page={pageNumber}
        onChangePage={onChangePage}
        rowsPerPage={pageSize}
        sort={sortType}
        handleChangeDuration={handleChangeDuration}
        handleSort={handleSort}
        sortField={sortField}
      />
      <CTAComponent {...ctaField} />
      <div className={classes.wrapper}>
        <img src={GifDone} alt="done-gif" className={classes.gifImage} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 45,
      marginBottom: 65,
      [theme.breakpoints.down('sm')]: {
        marginTop: 26,
        marginBottom: 42,
      },
    },
    gifImage: {
      height: 173,
      width: 173,
      [theme.breakpoints.down('sm')]: {
        height: 103,
        width: 103,
      },
    },
  };
});
