/* eslint-disable */
import { Box, makeStyles, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import TimePicker from 'components/TimePicker';
import TwelveBlocksPanelAdmin from 'components/TwelveBlocksPaneAdmin';
import { BlockActionTypeEnum } from 'enums/actions';
import { Orientations } from 'enums/orientations';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBlocksByTimeAction } from 'store/actions/blockActions';
import { getBlocksByTime, sTaskStatus } from 'store/selectors';

export const DISPLAY_TYPE = {
  ORIGINAL: 13,
  TWITTER_POST: 14,
  INSTAGRAM_POST: 15,
};

function TwelveBlocksExtra() {
  const classes = useStyle();
  const dispatch = useDispatch();

  const blocksByTimePath = useSelector(getBlocksByTime).path;
  const getBlockProcessing = useSelector(
    sTaskStatus(BlockActionTypeEnum.GET_BLOCKS_BY_TIME),
  )?.processing;

  const [value, setValue] = React.useState<Date | null>(new Date());
  const [includeDz, setIncludeDz] = useState<boolean>(false);
  const [idBlock, setIdBlock] = useState<number>(1);

  const [orientation, setOrientation] = useState<Orientations>(
    Orientations.TWITTER_POST,
  );

  const handleChangeOrientation = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setOrientation(event.target.value as Orientations);
  };

  const handleChangeTime = (value: Date | null) => {
    setValue(value);
  };

  const handleConfirmTime = () => {
    if (value)
      dispatch(
        getBlocksByTimeAction({
          date: value,
        }),
      );
  };

  const handleClick = (idBlock: number) => {
    setIdBlock(idBlock);
    setIncludeDz(false);
  };

  const handleClickAll = () => {
    setIncludeDz(false);
    if (orientation === Orientations.ORIGINAL) {
      setIdBlock(DISPLAY_TYPE.ORIGINAL);
    } else if (orientation === Orientations.TWITTER_POST) {
      setIdBlock(DISPLAY_TYPE.TWITTER_POST);
    } else {
      setIdBlock(DISPLAY_TYPE.INSTAGRAM_POST);
    }
  };

  const handleCopyDZ = () => {
    setIncludeDz(!includeDz);
  };

  const selectBlocks = useMemo(() => {
    return (
      <div className={classes.copyBlocks}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((block, index) => (
          <button
            className={clsx(classes.copyBtn)}
            key={index}
            onClick={() => {
              handleClick(block);
            }}
          >
            Copy {block}
          </button>
        ))}
        <button className={classes.copyBtn} onClick={handleClickAll}>
          Copy All
        </button>
        {orientation === Orientations.ORIGINAL && (
          <button className={classes.copyBtn} onClick={handleCopyDZ}>
            Copy DZ
          </button>
        )}
      </div>
    );
  }, [orientation]);

  useEffect(() => {
    dispatch(
      getBlocksByTimeAction({
        date: new Date(),
      }),
    );
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.top}>
        <Select
          value={orientation}
          onChange={handleChangeOrientation}
          classes={{
            root: classes.root,
          }}
          MenuProps={{
            classes: { paper: classes.dropdownStyle },
            variant: 'menu',
          }}
          disableUnderline
        >
          {Object.values(Orientations).map((v, i) => (
            <MenuItem key={i} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
        <div className={classes.time}>
          <TimePicker
            value={value}
            onChange={(value: Date | null) => handleChangeTime(value)}
          />
          <button onClick={handleConfirmTime}>Ok</button>
        </div>
      </Box>
      <Box className={classes.bot}>
        {selectBlocks}
        <TwelveBlocksPanelAdmin
          orientation={orientation}
          includeDz={includeDz}
          idBlock={idBlock}
          blocksByTimePath={blocksByTimePath}
          processing={getBlockProcessing}
        />
      </Box>
    </Box>
  );
}

export default TwelveBlocksExtra;

const useStyle = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
  },

  root: {
    width: 220,
    border: '1px solid #1b1464',
    borderRadius: 5,
    padding: 8,
  },

  dropdownStyle: {
    backgroundColor: '#FFFFFF',
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 100,
    '& button': {
      marginLeft: 20,
    },
  },
  bot: {
    marginTop: 50,
    display: 'flex',
  },
  copyBlocks: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 10,
  },
  copyBtn: {
    marginTop: 10,
    cursor: 'pointer',
    border: '1px solid #1b1464',
    padding: 3,
    borderRadius: 3,
  },
  active: {
    backgroundColor: 'red',
  },
  twelveBlocks: {
    marginLeft: 50,
  },
}));
